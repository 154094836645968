import { Suspense, lazy } from 'react';
import Loader from '../components/Loader';
import styled from 'styled-components';

const AboutUs = lazy(() =>  import('../components/AboutUs'));
const Accountable = lazy(() =>  import('../components/Accountable'));
const Achievements = lazy(() =>  import('../components/Achievements'));
const Gallery = lazy(() =>  import('../components/Gallery'))
const Projects = lazy(() =>  import('../components/Projects'))
const Sitemap = lazy(() =>  import('../components/Sitemap'))
const Slideshow = lazy(() =>  import('../components/Slideshow'))
const Story  =lazy(() =>  import('../components/Story'))
const Supporters = lazy(() =>  import('../components/Supporters'))
const Home = () => {
  return (
    <div style={{height:'100vh'}}>
        <Suspense fallback={<Loader/>}>
          <Slideshow/>
          <Spacer>
            <AboutUs/>
            <Achievements/>
            <Story/>
            <Accountable/>
            <Projects/>
            <Gallery/>
            <Supporters/>
          </Spacer>
        </Suspense>
        <Sitemap/>
    </div>
  )
}

export default Home;

const Spacer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  overflow: hidden;
  margin-right: 5px;
  @media (max-width: 768px) {
    padding:0px;
    margin-right: 0px;

    /* height: auto; */
    }
`