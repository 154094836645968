import { useEffect } from 'react';
import './styles/App.css';
import Router from './Router';
import { GlobalDebug } from "./remove-consoles";
// import ReactGA from 'react-ga';
// const TRACKING_ID = "G-RFKYBFWM2C";
// ReactGA.initialize(TRACKING_ID);


const App = ()=> {
  useEffect(() => {
    (process.env.NODE_ENV === "production" ||
     process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false);
  }, []);

  return (
    <div className="App">
        <Router/>
    </div>
  );
}

export default App;
