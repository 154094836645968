import React from 'react'
import styled from 'styled-components';
import '../styles/KROLoader.css';
const KROLoader = () => {
    
  return (
    <Container>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177.27 130.22" width="177.27000427246094" height="130.22000122070312"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1 svg-elem-1" d="M48.51,32.93s60.88-30,107.28-2.51a43.46,43.46,0,0,1,17.79,19.71c7,15.87,7,42.42-47.31,66.8l-3.36-3.6s34.32-14.76,36.84-42.48-42.84-40.32-42.84-40.32S84.51,20.45,51,35.93Z"></path><path class="cls-2 svg-elem-2" d="M48,32.86s59-27.77,105.43-.27A43.46,43.46,0,0,1,171.18,52.3c7,15.87,8.67,40-45.64,64.39L122,112.91c-.75.32,33.07-14.14,37.15-40.32,4.59-29.43-43.92-42.39-43.92-42.39s-30.92-9.39-64.4,6.09Z"></path><ellipse class="cls-1 svg-elem-3" cx="135.48" cy="21.99" rx="16.3" ry="22.26" transform="translate(1.99 53.89) rotate(-22.66)"></ellipse><path class="cls-3 svg-elem-4" d="M48.18,32.52S13.09,45.72,2.82,72.6a34,34,0,0,0,1.94,28.86c6.52,11.61,22,26.46,58.38,28.19,0,0,38.27,1.81,63.47-12.35l-4.08-4.39s-36.11,17.94-73.55.42-28.32-45.7-28.32-45.7,1.89-16,30.69-31.34Z"></path><path class="cls-4 svg-elem-5" d="M48.46,32.86S16.67,44.42,5.22,70.2c-4.11,9.27-3,20,1.94,28.86,6.52,11.61,22,26.46,58.38,28.19,0,0,35.3,3.6,60.5-10.56l-3.51-3.78s-37.19,18.66-74.75-.3C10.88,94,21.38,65.33,21.38,65.33s1.17-13.68,30-29Z"></path><ellipse class="cls-3 svg-elem-6" cx="38.75" cy="86.61" rx="22.26" ry="16.3" transform="translate(-53.5 64.39) rotate(-52.35)"></ellipse><ellipse class="cls-5 svg-elem-7" cx="39.58" cy="85.53" rx="19.54" ry="15.61" transform="translate(-52.31 64.63) rotate(-52.35)"></ellipse><ellipse class="cls-2 svg-elem-8" cx="134.75" cy="20.78" rx="15.4" ry="19.54" transform="translate(0.35 43.7) rotate(-18.45)"></ellipse><text class="cls-6" transform="translate(60.23 84.6)">K.R.O</text></g></g></svg>
    </Container>
  )
}

export default KROLoader;

const Container = styled.div`
    display:flex;
    align-items:center;
    justify-content: center;
    width:100vw;
    height:100vh ;
    background-color:rgba(194,194,194,0.1);
    padding:100px;
    box-sizing:border-box;
    overflow:hidden;
    @media (max-width: 768px) {
    padding:40px;

    }
    `