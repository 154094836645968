import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import 'rsuite/dist/rsuite.min.css';
import {BrowserRouter as Router} from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';
import "../node_modules/video-react/dist/video-react.css"; // import css
import "../node_modules/react-photo-view/dist/react-photo-view.css";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
      <AnimatePresence>
      <App />
      </AnimatePresence>
    </Router>
);


