import { Suspense, lazy, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import KROLoader from "./components/KROLoader";
import Home from "./pages/Home";

import ReactGA from "react-ga4";
ReactGA.initialize("G-RFKYBFWM2C");

const Announcement = lazy(() => import("./pages/Announcement"));
const ChildProtection = lazy(() => import("./pages/ChildProtection"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const Accountability = lazy(() => import("./pages/Accountability"));
const Cash_Based_Interventions = lazy(() =>
  import("./pages/Cash_Based_Interventions")
);
const Complaints_Feedback = lazy(() => import("./pages/Complaints_Feedback"));
const Contact_Us = lazy(() => import("./pages/Contact_Us"));
const FAQ = lazy(() => import("./pages/FAQ"));
const History_Milestone = lazy(() => import("./pages/History_Milestone"));
const Impact = lazy(() => import("./pages/Impact"));
const Livelihood_Food_Security = lazy(() =>
  import("./pages/Livelihood_Food_Security")
);
const Overview = lazy(() => import("./pages/Overview"));
const Programming_Approaches = lazy(() =>
  import("./pages/Programming_Approaches")
);
const Protection = lazy(() => import("./pages/Protection"));
const Shelter = lazy(() => import("./pages/Shelter"));
const Health = lazy(() => import("./pages/Health"));
const Story = lazy(() => import("./pages/Story/Story"));
const Success_Stories = lazy(() => import("./pages/Story/Success_Stories"));
const News_Letter = lazy(() => import("./pages/News/News_Letter"));
const ProjectNewsLetter = lazy(() => import("./pages/News/ProjectNewsLetter"));
const Team = lazy(() => import("./pages/Team"));
const Wash = lazy(() => import("./pages/Wash"));
const Work = lazy(() => import("./pages/Work"));
const News = lazy(() => import("./pages/News/News"));
const Photo_Gallery = lazy(() => import("./pages/Photos/Photo_Gallery"));
const Photo_Viewer = lazy(() => import("./pages/Photos/Photo_Viewer"));
const Video_Gallery = lazy(() => import("./pages/Videos/Video_Gallery"));
const Rfp = lazy(() => import("./pages/RFPs/Rfp"));
const RfpPage = lazy(() => import("./pages/RFPs/RfpPage"));
const Jobs = lazy(() => import("./pages/Jobs/Jobs"));
const JobPage = lazy(() => import("./pages/Jobs/JobPage"));
const JobApply = lazy(() => import("./pages/Jobs/JobApply"));

const Router = () => {
  useEffect(() => {
    const path = window.location.pathname + window.location.search;
    const pageName = window.location.pathname;
    if (process.env.NODE_ENV == "production") {
      ReactGA.send({ hitType: "pageview", page: path, title: pageName });
    }
  }, []);

  return (
    <Suspense fallback={<KROLoader />}>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* About Us Menu */}
        <Route path="/message" element={<Announcement />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/accountability" element={<Accountability />} />
        <Route path="/wherewework" element={<Work />} />
        <Route path="/historymilestone" element={<History_Milestone />} />
        <Route path="/complaintform" element={<Complaints_Feedback />} />
        <Route path="/ourteam" element={<Team />} />
        <Route path="/contactus" element={<Contact_Us />} />
        {/* About Us Menu */}
        {/* What We Do */}
        <Route path="/overview" element={<Overview />} />
        <Route path="/childprotection" element={<ChildProtection />} />
        <Route
          path="/programmingapproaches"
          element={<Programming_Approaches />}
        />
        <Route
          path="/livelihoodfoodsecurity"
          element={<Livelihood_Food_Security />}
        />
        <Route path="/wash" element={<Wash />} />
        <Route
          path="/cashbasedinterventions"
          element={<Cash_Based_Interventions />}
        />
        <Route path="/protection" element={<Protection />} />
        <Route path="/shelter" element={<Shelter />} />
        <Route path="/sbc" element={<Health />} />
        <Route path="/faq" element={<FAQ />} />
        {/* What We Do */}

        <Route path="/story" element={<Story />} />
        <Route path="/successstories" element={<Success_Stories />} />

        <Route path="/news" element={<News />} />
        <Route path="/newsletter" element={<News_Letter />} />
        <Route path="/news/:project" element={<ProjectNewsLetter />} />

        <Route path="/photogallery" element={<Photo_Gallery />} />
        <Route path="/photo" element={<Photo_Viewer />} />

        <Route path="/videogallery" element={<Video_Gallery />} />
        {/* <Route path='/video' element={<Photo_Viewer/>}/> */}

        <Route path="/rfps" element={<Rfp />} />
        <Route path="/rfp/:rfp/:id" element={<RfpPage />} />

        <Route path="/careeropportunities" element={<Jobs />} />
        <Route path="/job/:vacancy/:id" element={<JobPage />} />
        <Route path="/apply/*" element={<JobApply />} />
        <Route path="/impact" element={<Impact />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
