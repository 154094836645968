import React from 'react'
import { Loader as Indicator } from 'rsuite';
import styled from 'styled-components';
const Loader = () => {
  return (
    <Container>
     <Indicator content='Fetching Data' vertical />
    </Container>
  )
}

export default Loader;

const Container = styled.div`
    display:flex;
    align-items:center;
    justify-content: center;
    width:100vw;
    height:100vh ;
    background-color:rgba(194,194,194,0.1);
    padding:100px;
    box-sizing:border-box;
    overflow:hidden;
    @media (max-width: 768px) {
    padding:40px;

    }
    `